import React, { useEffect, useState } from "react";

import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";
import { ColorRing } from "react-loader-spinner";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.13.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  const [lauchMeeting, setLaunchMeeting] = useState(true);
  const params = new URLSearchParams(window.location.search);
  console.log(params);

  var authEndpoint = "https://newsignature.herokuapp.com/";
  var sdkKey = "7VDgcS7y8ykARL8reNyK88zVgBXYGL0iobcY";
  var meetingNumber = params.get("idMeeting");
  var passWord = "";
  var role = 0;
  var userName = params.get("userName");
  var userEmail = params.get("email");
  var registrantToken = "";
  var zakToken = "";
  var leaveUrl = window.location.origin;

  const handlePostMessage = (event) => {
    // Aquí puedes manejar los datos recibidos en el mensaje
    console.log("Mensaje recibido:", event.data);
  };

  useEffect(() => {
    setTimeout(function () {
      document.getElementById("start-my-meeting-swz").click();
      setLaunchMeeting(false);
    }, 2000);
    console.log("SharedArrayBuffer: ", typeof SharedArrayBuffer === "function");
    window.addEventListener("message", handlePostMessage);
    return window.removeEventListener("message", handlePostMessage);
  }, []);

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: params.get("idMeeting"),
        role: params.get("email") == params.get("inputEmail") ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function startMeeting(signature) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log("Error en el Join: ",error);
          },
        });
      },
      error: (error) => {
        console.log("Error en el Init: ",error);
      },
    });
  }

  return (
    <div className="App">
      <main className="meeting-container-personalized">
        {lauchMeeting && (
          <>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#B01E55", "#B01E55", "blue", "#B01E55", "#blue"]}
            />
            <h3 style={{ fontWeight: "bold" }}>Loading Meeting...</h3>
          </>
        )}
        <button id="start-my-meeting-swz" onClick={getSignature}>
          Join Meeting
        </button>
      </main>
    </div>
  );
}

export default App;
